.tribe,
.leadership-team,
.news-stories {
    padding: @sectionTopBottomPadding 0;
}

.tribe{
	background-color: @darkerDark;
	
	>.container{
		max-width: 100%;
		
		>.row{
			align-items: center;
			margin: 0px;
		}
	}
	
	h2,p{
		color: white;
	}
	
	h2{
		text-align: left;
	}
	
	p{
		font-size: 1.250rem;
		line-height: 1.875rem;		
	}
	
	.section-heading {
	    color: @influenceLightGreen;
			text-align: left;
	}
	
	.wow{
		img{
			width: 100%;
			max-width: 420px;
			display: block;
			margin: 1rem auto;
			height: auto;
			-webkit-box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.2);
			-moz-box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.2);
			box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.2);				
		}
	}
}

.leadership-team{
  background-color: @darkerWhite;
  
  .section-subheading{
	  margin-top: 0;
  }
	
	.wall{
		width: 90%;
		margin: 0 auto;
		
		.frame{
			margin-bottom: 2rem;
			
			//frame headshots
			>div:first-child{
				position: relative;
				margin: 0 .5rem;
				
				img{
					height: auto;
					position: absolute;
					width: 52.097902%;
					left: 23.776223%;
					top: 23.776223%;
				}
				
			}
			>div:first-child:before{
		    background-image: url('../images/frame-team.png');
		    display: inline-block;
		    width: 100%;
		    content: "";
		    padding-top: 100%;
		    background-size: contain;
		    background-repeat: no-repeat;
				-webkit-box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.2);
				-moz-box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.2);
				box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.2);						
			}
			>div.wide{
		    img{
			    width: 69.19642%;
			    left: 15.4017857%;
			    top: 18.181818%
		    }				
			}			
			>div.wide:first-child:before{
		    background-image: url('../images/frame-team-wide.png');
		    padding-top: 83.48214%; 
			}
			
			//name and title
			>div:last-child{
				text-align: center;
				margin-top: 1.5rem;
				
				span{
					font-size: 1.375rem;
					font-weight: bold;
				}
				small{
					font-size: .875rem;
				}
			}			
		}

		.object-container{
			width: 100%;
			padding-top: 100%;
			position: relative;
			
			img{
				position: absolute;
				margin: auto;
				width: 75%;					
				right: 0;
				left: 0;
				top: 0;
				bottom: 0;				
			}
			
			img.shadow{
				-webkit-box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.2);
				-moz-box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.2);
				box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.2);					
			}
		}
		
		.object-container.double-tall{
			img{
				top: 20%;
				bottom: auto;
			}
		}	

		.object-container.double-wide{
			padding-top: 55%;
		}	

	}
	
}

.news-stories{
	
	>.container{
		max-width: 100%;
		
		>.row{
			margin: 0px;
		}
	}
	
  .section-subheading{
	  margin-top: 0;
  }	
  
  article{
	  margin-bottom: 4rem;
	  
	  img{
		  width: 86%;
		  height: auto;
			display: block;
			margin: 0 auto;
	  }
	  
	  header,p{
		  width: 86%;
		  margin: 0 auto;
	  }
	  
	  header{
		  h1{
		  	color: @influenceLightGreen;
				font-size: 1.5rem;
		  }
	  }
	  
	  p{
		  a{
		    display: block;
		    margin-top: 2rem;
		    color: @influenceLightBlue;
		    text-decoration: none;
		    font-weight: bold;			  
		  }
	  }
  }
  
}

.company-page-footer{
	background-color: @darkerWhite;
	
	>.container{
		max-width: 100%;
		
		>.row{
			margin: 0px;
		}
	}
	
	h1.section-subheading{
		color: @influenceLightGreen;
	}
	
	a{
		color: @influenceLightBlue;
	}
}




// Tablet Large
@media only screen and (min-width: @tabletMaxWidth) {
	.tribe{
		.wow{
			img{
				max-width: none;
				margin: 0;
			}
		}
	}
}