.ivx-services-overview-section,
.ivx-services-section,
.ivx-services-content-creators-callout {
    padding-top: @sectionTopBottomPadding;
    padding-bottom: @sectionTopBottomPadding;
}

.ivx-services-overview-section {
    color: @lightestDark;

    figure {
        margin: 0;
        img {
            max-width: 100%;
        }
    }
}
    .ivx-services-overview-header {
        .section-heading {
            color: @influenceLightGreen;
        }
    }
    .service-details {
        margin-bottom: 2rem;
    }
    .ivx-services-overview-description {
        font-size: 1.250rem;
        line-height: 1.875rem;
    }
    .ivx-services-overview-image-caption {
        color: @influenceLightBlue;
        text-transform: none;
        font-size: 1rem;
        line-height: 1.5rem;
        margin-top: 1.563rem;
        font-style: italic;

        span {
            color: @lightestDark;
            display: block;
            font-size: 0.813rem;
        }
    }

.ivx-services-section {
    background-color: @darkerWhite;

    h3 {
        margin: 0 0 1.375rem;
        font-size: 2.250rem;
    }
    .service-image {
        max-width: 100%;
    }
    figure {
        margin: 0 0 1.688rem;
        .clearfix();
    }
    img {
        max-width: 100%;
    }
}
    #tech-enabled-services,
    #content-services {
        margin-bottom: 5rem;
    }

    #tech-enabled-services,
    #digital-services {
        h3 {
            color: @influenceLightGreen;
        }
    }

    #content-services {
        h3 {
            color: @influenceLightBlue;
        }
    }

    .ivx-services-service-caption {
        color: @darkerDark;
        font-size: 0.875rem;
        line-height: 1.313rem;
        font-style: italic;
    }

.ivx-services-page-footer{
	>.container{
		max-width: 100%;
		
		>.row{
			margin: 0px;
		}
	}
}

.ivx-services-content-creators-callout {
    h1 {
        color: @influenceLightGreen;
        font-size: 8vw;
        margin: 0 0 1.5rem;
        font-weight: 900;
        text-align: center;
    }

    p {
        color: @darkerLight;
        font-weight: 100;
        font-size: 5.5vw;
        line-height: 1.600em;
    }
}


// Tablet
@media only screen and (min-width: @tabletMinWidth) {
    .ivx-services-overview-section figure img {
        max-width: initial;
    }


    .ivx-services-section {
        .service-details {
            margin-left: 2rem;
        }
        img {
            max-width: initial;
        }
        .content-services-image img {
            float: right;
        }
    }

    .ivx-services-content-creators-callout {
        h1 {
            font-size: 4rem;
        }

        p {
            font-size: 1.50rem;
        }
    }
}