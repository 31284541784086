// INPUTS
.labeled-input {
    margin-bottom: 0.875rem;
    display: flex;
    align-items: center;
    background: @white;
}
    .labeled-input-label {
        color: @labelColor;
        font-weight: 400;
        font-size: 0.750rem;
        text-transform: uppercase;
        border-right: 1px solid @labelDividerColor;
        min-width: 6em;
        padding: .6rem 0.875rem;
        text-align: right;
    }
    .labeled-input-input {
        background: @white;
        border: 1px solid @white;
        width: 100%;        
    }
    .labeled-input-input {
        padding: 0.875rem;
    }


.form-input {
    margin: .5rem auto;

    label {
        display: none;
    }

    input, select, textarea {
        background: @white;
        border: none;
        box-sizing: border-box;
        color: @darkerLight;
        display: block;
        font-size: 1.125rem;
        font-weight: 400;
        padding: 0.750rem 1rem;
        width: 100%;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance : none;
        appearance: none;
        height: 2.750rem;
        padding: ;
    }

    button[type="submit"] {
        .makeButton(@influenceLightGreen, @white, @influenceLightGreen);
    }
}



// BUTTONS

button, .button {
    .makeButton(@darkerDark, @white, @darkerDark);
}

.button-blue {
    .makeButton(@influenceLightBlue, @white, @influenceLightBlue);
}
.button-green {
    .makeButton(@influenceLightGreen, @white, @influenceLightGreen);
}
.button-orange {
    .makeButton(@influenceLightOrange, @white, @influenceLightOrange);
}