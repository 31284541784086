.hero-home {
    background-image: url('../images/home-gradient-bg.jpg');
    background-repeat: repeat-x;
    height: 60vw;
    max-height: 48rem;
    margin-bottom: 10vw;

    &:before {
        background-image: url('../images/ivx-influence-technologies-no-flat-content-mobile.jpg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center bottom;

        content: '';    
        height: 100%;
        display: block;
    }
}

.page-primary-header-text {
    margin: 0 auto 5vw;
    font-size: 8vw;
    font-weight: 900;
}
.page-primary-subheader-text {
    font-weight:normal;
    font-size: 4.5vw;
    line-height: 6vw;
    margin-bottom: 11vw;
}

.ivx-feature {
    margin: 0 0 3.750rem;
}
    .ivx-feature-image {
        margin-bottom: 1.250rem;
        max-width: 100%;
    }
    .ivx-feature-image-caption {
        font-size: 0.875rem;
        font-weight: bold;
        width: 55%;
        margin: 0 auto;
    }



.biz-tech-prop-section {
    background-color: @darkerWhite;
    padding: 5.375rem 0;
    
    >.container{
	    max-width: 100%;
    }
}
    .biz-prop-section {
	    	margin: 0 0 5rem;
    }
        .biz-prop-heading {
            color: @influenceLightGreen;
        }


    .tech-prop-section {
	    margin: 0;
    }
        .tech-prop-section-image {
            float: right;
        }
        .tech-prop-heading {
            color: @influenceLightBlue;
        }

    .biz-prop-heading,
    .tech-prop-heading {
        margin: 0 0 1.375rem;
        font-size: 2.250rem;
        font-weight: 900;
    }
    .biz-prop-details,
    .tech-prop-details {

        button {
            margin: 0 auto 7vw;
            text-align: center;
            display: block;
        }
    }
    .biz-prop-section-image,
    .tech-prop-section-image {
			margin: 1em 0;
    }

    .influence-partners,
    .ivleague-form-aside {
        padding-top: 5.625rem;
    }
    .influence-partners-heading,
    .ivleague-form-heading {
        margin:0 0 1.125rem;
        font-size: 2.125rem;
        line-height: 1.087em;
        font-weight: 900;
    }

    .influence-partners-heading {
        color: @influenceLightBlue;
    }
    .influence-partners-logos {
        padding-bottom: 5.625rem;
        margin: 0;

        img {
            max-width: 100%;
        }
    }


.ivleague-form-aside {
    background-color: @influenceLightGreen;
    padding-left: 2.875rem;
    padding-right: 2.875rem;
}
    .ivleague-form-heading {
        color: @white;
    }
    .ivleague-form {
        margin-top: 2.250rem;
    }
        .ivleague-form-submit-button {
            display: block;
            margin: 1.750rem auto;
        }

.home-page-footer-section{
	max-width: 100%;
	
	>.row{
		margin: 0px;
	}
}


// Tablet
@media only screen and (min-width: @tabletMinWidth) {
		.hero-home {
        margin-bottom: 5.250rem;

		    &:before {
		        background-image: url('../images/ivx-influence-technologies-no-flat-content-tablet.jpg');
		    }
		}

    .page-primary-header-text {
        margin: 0 auto 2.875rem;
        font-size: 4rem;
    }
    .page-primary-subheader-text {
        font-size:1.250rem;
        line-height: 1.875rem;
        margin-bottom: 4rem;
    }

        .biz-prop-details,
        .tech-prop-details {
            margin-left: 2rem;

            button {
                margin: 0;
                display: inline-block;
            }
        }

        .biz-prop-section-image,
        .tech-prop-section-image {
            img {
                max-width: initial;
            }    
        }
}

@media only screen and (min-width: @tabletMaxWidth) {
		.hero-home {
        margin-bottom: 5.250rem;
		
		    &:before {
		        background-image: url('../images/ivx-influence-technologies-no-flat-content-alt.jpg');
		    }
		}
}

// Mobile
@media only screen and (max-width: 767px) {

    .biz-prop-section-image,
    .tech-prop-section-image {
			img{
				max-width: 100%;
			}
    }
	
}