.contact-header {
    height: 250px;
    background-image: url('../images/influence-location-taxi-denver-colorado.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.contact-primary-content {
    padding: @sectionTopBottomPadding 0;
    background-color: @darkerWhite;
}
    
    .contact-form-section {}
        .contact-form-description {
            font-size: 1.125rem;
            line-height: 1.688rem;
            margin-top: 0;
        }
        .contact-influence-form {}
        button[type=submit]:disabled,
        button[type=submit].disabled{
	        opacity: .3;
        }


    .contact-information-group {
        margin-bottom: 2.250rem;
    }
        .contact-information-heading {
            color: @influenceLightGreen;
            text-transform: uppercase;
            font-size: 1rem;
            margin: 0;
        }
        .contact-information-detail {
            font-size: 1.313rem;
        }
        .contact-information-detail-secondary {
            font-size: 1rem;
        }
        .contact-information-address {
            font-size: 1rem;
            line-height: 1.313rem;
        }

.contact-directions-section {
    padding: @sectionTopBottomPadding 0;
    max-width: 100%;
    
    >.row{
	    margin: 0;
    }
}
    .contact-directions-description {
        font-size: 4.5vw;
        line-height: 1.438em;
    }
    .contact-directions-button {
        .makeButton(@influenceLightBlue, @white, @influenceLightBlue);
        margin-bottom: 2rem;
    }
    
    #map {
        height: 400px;
        max-width: 100%;
    }

// Tablet
@media only screen and (min-width: @tabletMinWidth) {

        .contact-directions-description {
            font-size: 1.625rem;
            line-height: 2.438rem;
        }
        .contact-directions-button {
            float: right;
        }
        #map {
            height: 400px;
            width: 400px;
        }
}

// Tablet
@media only screen and (max-width: 767px) {
	.contact-primary-content{
		aside{
			margin-top: 3rem;
		}
	}	
}