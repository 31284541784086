.global-footer {
    background-color: @darkerDark;
    padding: 5.5rem 0;
    overflow: hidden;
    
    >.container{
	    max-width: 100%;
    }
    
    nav{
	    margin: 0;
	    
	    section{
		    padding: 0 1%;
	    }
    }

    a {
        color: @white;
        text-decoration: none;
    }
    ul {
        display: none;
        margin: 0;
        padding: 0;
        font-size: .875rem;
        
        li:before{
	        display: none;
        }

        a {
            font-weight: lighter;
            line-height: 1.313rem;
        }
    }
    
    ul.social-share{
	    display: block;
	    margin-top: 2rem;
	    
	    li{
		    display: inline;
		    
		    img{
			    max-width: 17.5%;
		    }
	    }
    }
}
    .footer-page-section {
        
    }
        .footer-page-heading {
            margin: 1.5rem 0 0;
            font-size: 0.875rem;
            font-weight: normal;
            padding-top: 0.563rem;
            border-top: 1px solid @lighterDark;
        }

// Tablet
@media only screen and (min-width: @tabletMinWidth) {
    .global-footer {
        ul {
            display: block;
        }
    }
        .footer-page-heading {
            margin: 0 0 1.500rem;
            font-size: 0.875rem;
            font-weight: normal;
            padding-top: 0.563rem;
            border-top: 1px solid @lighterDark;
        }
}

// Tablet
@media only screen and (min-width: @tabletMaxWidth) {
    .global-footer {
        ul {
            font-size: 1rem;
        }
    }
}