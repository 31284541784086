/*  General LESS Rules for Easy Maintainability and Awesome Performance 
 *   - Namespace element selectors following BEM convention like:
 *     .global-header-nav-item (good) vs .global-header nav ul li (bad)
 *   
 *   - Shallow nesting
 *     Only nest maximum of 3 selectors deep
 *   
 *   - Write mixins
 *     Code snippets that need to be used in many places can be written with DRY principles by creating custom mixins like for buttons
 *   
 *   - Indent nested element
 *     Nested, grouped elements should be indented to visually represent markup hierarchy and for easier scanning
 *  
 *  
 *  Resources:
 *   - http://blog.trello.com/refining-the-way-we-structure-our-css-at-trello
 */


// Libraries
@import         "lib/ngDialog.less";


// Variables + Mixins
@import         "less/variables.less";
@import         "less/mixins.less";


// Base
@import         "less/base/normalize.less";
@import         "less/base/typopgraphy.less";
@import         "less/base/flexboxgrid.less";
@import         "less/base/inputs-buttons.less";
@import         "../fonts/influence.css";


// General Site
@import         "less/header.less";
@import         "less/footer.less";


// Pages
@import         "less/pages/all-pages.less";
@import         "less/pages/home.less";
@import         "less/pages/platform.less";
@import         "less/pages/gallery.less";
@import         "less/pages/services.less";
@import         "less/pages/company.less";
@import         "less/pages/contact.less";


// Responsive
@import         "less/responsive-tablet.less";
@import         "less/responsive-laptop.less";
@import         "less/responsive-large-desktop.less";


// Print
