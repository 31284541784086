.how-it-works,
.ivx-features,
.ivx-partners,
.ivx-pricing,
.ivx-developer-resources {
    padding: @sectionTopBottomPadding 0;
}



.how-it-works {
    .section-heading {
        color: @influenceLightGreen;
    }
}

    .how-it-works-description {
        font-size: 1.125rem;
        color: @textLightColor;
        margin-top: 2rem;
        margin-bottom: 2rem;
        line-height: 1.688rem;
    }
        .how-it-works-description-heading {
            color: @textColor;
            text-transform: uppercase;
            display: block;
        }

    .how-it-works-image-carousel {

    }
        .how-it-works-image {
            max-width: 100%;
        }
        .how-it-works-image-caption {
            width: 67.5%;
            margin: 1.750rem auto 0;
            font-size: 1rem;
            color: @influenceLightBlue;
            text-transform: none;
            font-weight: normal;
            line-height: 1.500rem;
            font-style: italic;
        }

.ivx-features {
    background-color: @darkerWhite;

    .section-heading {
        color: @influenceLightBlue;
    }
}
    .ivx-features-section {
        margin: 5.500rem 0 0;

        h3 {
            font-size: 2.250rem;
            margin: 0 0 0.625rem;
        }
        ul, figure {
            margin: 0;
        }
        img {
            max-width: 100%;
            margin-top: 8vw;
        }
    }
    .ivx-features-section:nth-child(even) {
        h3 {
            color: @influenceLightGreen;
        }
    }
    .ivx-features-section:nth-child(odd) {
        h3 {
            color: @influenceLightBlue;
        }
    }




.ivx-partners {
    .section-heading {
        color: @influenceLightBlue;
        margin-bottom: 2.650rem;
    }
    img {
        max-width: 100%;
    }
}




.ivx-pricing {
    background-color: @influenceLightGreen;
    
    >.container{
	    max-width: 100%;
	    
	    >.row{
		    margin: 0;
	    }
    }
    
    img{
	    max-width: 100%;
	    padding-bottom: 3px;
    }

    .section-secondary-subheading {
        color: @white;
    }
}
    .ivx-pricing-table {
        padding: 0;
    }
    	.table-container{
	    	position: relative;
    	}
    
        .ivx-pricing-table-contact {
            position: absolute;
            top: 48%;
            left: 6%;
            width: 86%;
            text-align: center;
            color: white;
            font-size: 3.25vw;
            line-height: 1.25em;
        }
    .ivx-pricing-platform-checklist-box {
        background: @white;
        padding: 1.750rem;

        h3 {
            color: @influenceLightGreen;
            font-size: 1rem;
            margin: 0 0 .750rem;
            text-align: center;
        }
    }
        .ivx-pricing-platform-checklist {
            font-size: 0.875rem;
            margin: 0;

            li {
                line-height: 1.076rem;
                margin-bottom:.8em;
                
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    .ivx-pricing-special-pricing {
        margin-top: 3.000rem;
        text-align: center;
    }





.ivx-developer-resources {
    .section-heading {
        color: @influenceLightGreen;
    }
    .section-secondary-subheading {
        margin-bottom: 4.125rem;
    }
    figure {
        padding: 0;
        margin: 8vw 0 0;
    }
}
    .ivx-developer-resources-description {
        font-size: 1.250rem;
        line-height: 1.875rem;
        margin: 0 0 2.500rem;
    }





// Tablet
@media only screen and (min-width: @tabletMinWidth) {
    .ivx-features-section {
        aside {
            display: flex;
            align-items: center;
        }
        img {
            margin-top: 0;
        }
    }


    .ivx-pricing-table-contact {
        font-size: 1.5rem;
    }

    .ivx-developer-resources {
        figure {
            margin: 0;
        }
    }
}

// Large Tablet
@media only screen and (min-width: @tabletMaxWidth) {
	.ivx-pricing {

	    img {
	        max-width: 98%;
	    }
	}	
}

// Large Screens
@media only screen and (min-width: @desktopMaxWidth) {
    .ivx-pricing-table-contact {
        font-size: 1.875rem;
        line-height: 2.625rem;
    }	
}

// Mobile
@media only screen and (max-width: 767px) {
	.ivx-developer-resources{
		img{
			max-width: 100%;
		}
	}
}