/*  Load all web fonts asyncronously via web font loader javscript in root index.html
 *    - https://github.com/typekit/webfontloader
 *  
 *  Resources:
 *   - https://helloanselm.com/2015/using-webfonts-in-2015/#avoid-reflows (a little dated)
 */


html {
    font-family: 'Lato', sans-serif;
    color: @textColor;
    letter-spacing: -0.00991em;
    font-size:16px;

    &.wf-active {
        letter-spacing: initial;
    }
}

h1, h2, h3, h4, h5, h6 {
    color:@headerColor;
    font-weight:700;
}


figcaption {
    text-transform: uppercase;
}

p {
    font-size:1rem;
    line-height:1.500rem;
}

ul {
    margin: 2rem 0;
    padding-left: 1.5rem;

    li {
        line-height:1.5rem;

        &:before {
            content: '\2022';
            margin-left: -1.5rem;
            width: 1.5rem;
            display: inline-block;
        }
    }

    // Nested Lists
    ul {
        margin: 0;
    }
}

ul.check-bullets {
    list-style: none;

    li:before {
        height: 1rem;
        width: 1.5rem;
        display: inline-block;
        font-family: influence;
        content: '\f00c';
    }
    ul {
        list-style: none;
    }
}
ul.no-bullets {
    list-style: none;
    padding: 0;

    li:before {
        content: '';
    }
}



// Style modifiers
.center-text {
    text-align: center;
}


.superscript,
.subscript {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
    font-size: smaller;
}
.subscript {
    top: 0.4em;
}


.no-mobile {
    display: none;
}

.pull-right {
    float:right;
}

.sr-only {
    display: none;
}

.clearfix{
  zoom:1;
  &:before, &:after{ content:""; display:table; }
  &:after{ clear: both; }
}