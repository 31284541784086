.home .global-header {
    background-color: transparent; // hide nav background on home when not scrolled only
    padding: 0 3.25%;
}
.home.fixed .global-header {
    background-color: fade(@darkerLight, 95);
}

.global-header {
    background-color: fade(@darkerLight, 95);
    display: flex;
    margin: 0 auto;
    height: @headerNavShort;
}
    .global-header-container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: stretch;
        align-items: center;
        width: 100%;
        padding: 0 1.25vw;
    }
        .global-header-logo {
            display: flex;
            max-height: 50%;
            align-self: center;
        }
        .global-header-logo-image {
            display:block;
        }
        .global-header-nav,
        .global-header-login {
            display: none;
        }
        .global-header-mobile-menu {

            button {
                .makeButton(transparent, @white, @white);
                max-height: 50%;
                padding: 2vw;
            }
            .icon-bar {
                display: block;
                width: 22px;
                height: 2px;
                border-radius: 1px;
                background-color: @white;
                
                &+.icon-bar {
                    margin-top: 4px;
                }
            }
        }
        .global-header-nav.open {
            position: absolute;
            top: @headerNavShort;
            left: 0;
            right: 0;
            display: block;
            align-self: flex-start;
            background-color: fade(@darkerLight, 95);
            width: 100%;
            /*transition: all 1s ease;*/

            a {
                display: block;
                color: @white;
                text-decoration: none;
                margin: 4vh;
                font-size: 1.5em;
            }

            &+.global-header-mobile-menu {
                display: none;
            }
        }



// Fix the header to the top
.fixed {
    .global-header {
        height: @headerNavShort;
    }
    .page-content-area {
        margin-top: @headerNavShort;
    }
}

.global-header {
    transition: height .5s;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
}
.home .page-content-area {
    margin-top: 0;
}
.page-content-area {
    margin-top: @headerNavShort;
}




// Tablet
@media only screen and (min-width: @tabletMinWidth) {
    .global-header {
        height: @headerNavTall;
    }
        .global-header-container {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-content: stretch;
            align-items: initial;
            width: inherit;
            padding: 0;
        }
            .global-header-nav {
                display: flex;
                justify-content: space-around;
                margin: 0 5vw;
                flex: 2 1 auto;
                align-content: stretch;
            }
                .global-header-nav-link {
                    color: @white;
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: 500;
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    padding: 0 1rem;

                    &.active {
                        background-color: fade(black, 10);
                    }
                }
            .global-header-login {
                display: flex;
                align-items: center;
            }
                .global-header-login-button {
                    .makeButton(transparent, @white, @white);
                    font-size: 12px;
                    padding: .8em 1.65em;
                }
            .global-header-mobile-menu {
                display: none;
            }

    .page-content-area {
        margin-top: @headerNavTall;
    }
}

@media only screen and 
	(min-width: @tabletMinWidth) and
	(max-width: @tabletMaxWidth) {
	
	.global-header{
		
		.global-header-container{
	    flex-direction: column;
	    position: relative;			

			.global-header-logo{
				align-self: flex-start;
				padding: 1.5rem 0 1rem;
			}
			
			.global-header-nav{

			}
			
			.global-header-login{
				position: absolute;
				top: 1.5rem;
				right: 1.5rem;
			}
		}
	}
	
	.fixed{
		.global-header{
			.global-header-logo,
			.global-header-login{
				display: none;
			}
			
			.global-header-nav{
				padding-bottom: 0;
			}
		}
	}
	
}