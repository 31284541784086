html {
    background-color: @lighterLight;
}
.page-content-area {
    background-color: @white;
    overflow: hidden;
}


// Styles for headings on Platform, Gallery
.section-heading,
.section-subheading,
.section-secondary-subheading{
	max-width: 96%;
	margin-left: auto;
	margin-right: auto;
}
.section-heading {
    margin-bottom: 5vw;
    font-size: 1rem;
    text-transform: uppercase;
    text-align:center;
}
.section-subheading {
    font-size: 7.5vw;
    line-height: 1.1em;
    font-weight: 900;
    margin-bottom: 5vw;
    text-align: center;
}
.section-secondary-subheading {
    font-size: 2.250rem;
    line-height: 2.438rem;
    text-align: center;
}
.section-intro{
		font-size: 1.375rem;
		line-height: 1.5em;
		margin-bottom: 4rem;
}
.section-intro-large{
		font-size: 5vw;;
		line-height: 1.5em;
		margin-bottom: 4rem;
		font-weight: 100;
		margin-top: 0;
}


// All forms
/*.ivx-form-thanks {
    display: none;
}*/

button{
	cursor: pointer;
}




// Tablet
@media only screen and (min-width: @tabletMinWidth) {
    // Styles for headings on Platform, Gallery
    .section-heading {
        margin-bottom: 1.625rem;
        font-size: 1rem;
    }
    .section-subheading {
        font-size: 3.75rem;
        line-height: 1em;
        margin-bottom: 2.750rem;
    }
    .section-secondary-subheading {
        font-size: 2.250rem;
        line-height: 2.438rem;
    }
	.section-intro-large{
			font-size: 2rem;
	}    
}

// Mobile
@media only screen and (max-width: 767px) {
	.xs-hide{
		display: none;
	}	
}