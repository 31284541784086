.ivx-innovation-gallery {
    padding: @sectionTopBottomPadding 0 0;
    
    >.container{
	    max-width: 100%;
	    
	    >.row{
		    margin: 0;
	    }
    }

    .section-heading {
        color: @influenceLightGreen;
    }
    
    aside{
	    align-self: flex-end;
    }

    figure {
        margin-top: 0;
        margin-bottom: 0;
    }
    
    img{
	    display: block;
	    max-width: 100%;
    }
}
    .innovation-gallery-description {
        color: @textLightColor;
        margin-bottom: 5vw;

        p, h3, ul li {
            font-size: 1.250rem;
            line-height: 1.875rem;
        }
        h3 {
            font-weight: normal;
            color: @influenceLightBlue;
        }
        ul {
            margin:0;
        }
    }


// Gallery Navigation show/hide
// Before locking viewport on gallery
.slider-container {
    position: relative;
}
    .slider-navigation-container {
        display: none;
        position: absolute;
        height: 100vh;
        top: 0;
        max-width: 15%;
    }
// Lock the viewport on the gallery
body.gallery-carousel-locked-view {
    .global-header {
        background-color: fade(@darkerLight, 65);
    }
    .slider-navigation-container {
        display: none;
        position: fixed;
        top: @headerNavShort;
        height: ~'calc(100% - 4rem)';
    }
}
body.gallery-carousel-at-bottom {
    .slider-navigation-container {
        bottom: 0;
        top: initial;
    }
}



// Gallery Slider Layout
.slider-container {
    
}
    .slider-panel-container {

    } 
        .innovation-gallery-section {
            min-height: 100vh;
            
            .feature-holder{
	            padding: 0 5%;
            }  
        }
        
    .slider-navigation-container {
        right: .5rem;
        bottom: 0;

        ul {
            margin: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-wrap: nowrap;
            align-items: flex-start;
        }
        li {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            border-right-width: .5rem;
            border-right-style: solid;

            &.is-active a,
            a.is-active {
                background-color: @white;
                font-size: 1rem;
                font-weight: bold;
            }

            &:nth-child(1) {
                border-right-color: @influenceGreen;

                &.is-active a,
                a.is-active {
                    color: @influenceGreen;
                }
            }
            &:nth-child(2) {
                border-right-color: @influenceBlue;

                &.is-active a,
                a.is-active {
                    color: @influenceBlue;
                }
            }
            &:nth-child(3) {
                border-right-color: @influenceGray;

                &.is-active a,
                a.is-active {
                    color: @influenceGray;
                }
            }
            &:nth-child(4) {
                border-right-color: @influenceGrass;

                &.is-active a,
                a.is-active {
                    color: @influenceGrass;
                }
            }
            &:nth-child(5) {
                border-right-color: @influenceForest;

                &.is-active a,
                a.is-active {
                    color: @influenceForest;
                }
            }
            &:nth-child(6) {
                border-right-color: @influenceSky;

                &.is-active a,
                a.is-active {
                    color: @influenceSky;
                }
            }
            &:nth-child(7) {
                border-right-color: @influenceOrange;

                &.is-active a,
                a.is-active {
                    color: @influenceOrange;
                }
            }
            &:nth-child(8) {
                border-right-color: @influenceBasicGreen;

                &.is-active a,
                a.is-active {
                    color: @influenceBasicGreen;
                }
            }
        }
        a {
            text-decoration: none;
            display: flex;
            color: @white;
            font-size: 0.750rem;
            font-weight: bold;
            height: 100%;
            align-items: center;

            span {
                display: block;
                padding: 0 1rem;
            }
        }
    }



// Generic Gallery Panel Styles
.innovation-gallery-section {
    padding: 5.000em 0;
    ul li {
        font-size: 0.875rem;
        line-height: 1.313rem;
        padding-bottom: .275rem;
    }

    figure {
        margin: 0 0 0 -3vw;
    }
    img {
        max-width: 100%;
    }
}
    .innovation-gallery-section-heading {
        font-size: 10vw;
        text-align: center;
    }
    .innovation-gallery-section-description {
        margin-top: 0;
        font-size: 4.5vw;
        line-height: 1.6em;
    }
    .innovation-gallery-subheading {
        font-size: 1.125rem;
        line-height: 1.313rem;
    }
    .innovation-gallery-demo-description {
        font-size: 0.875rem;
        line-height: 1.313rem;
        font-style: italic;
    }
    .innovation-gallery-demo-link {
        position: relative;
        display: block;
        margin-top: 8vw;

        button {
            position: absolute;
            top: -10px;
            right: -10px;
        }
    }

.innovation-gallery-section-navigation {
    position: absolute;
}




// Common section styles for text colors
#sales,
#lead-capture,
#lead-conversion,
#winback-remine,
#virtual-coaching,
#dhs {
    .innovation-gallery-section-heading,
    .innovation-gallery-section-description,
    .innovation-gallery-benefits-list,
    .innovation-gallery-demo-description {
        color: @white;
    }

    .innovation-gallery-subheading {
        color: @black;
    }
}
#crm-loyalty {
    .innovation-gallery-section-heading {
        color: @white;
    }

    .innovation-gallery-section-description,
    .innovation-gallery-benefits-list,
    .innovation-gallery-demo-description,
    .innovation-gallery-subheading {
        color: @black;
    }
}
#training-education {
    .innovation-gallery-section-heading,
    .innovation-gallery-section-description,
    .innovation-gallery-benefits-list,
    .innovation-gallery-demo-description {
        color: @white;
    }

    .innovation-gallery-subheading {
        color: @influenceGoldenrod;
    }
}



// Section specific styles
#sales,
#lead-capture,
#lead-conversion,
#winback-remine,
#virtual-coaching,
#dhs,
#crm-loyalty,
#training-education{
	display: flex;
	flex-direction: column;
	justify-content: center;	
	box-sizing: border-box;
}

#sales {
    background-color: @influenceGreen;

    button {
        .makeButton(@lightestDark, @white, @lightestDark);
    }
}
#lead-capture {
    background-color: @influenceBlue;

    button {
        .makeButton(@black, @white, @black);
    }
}
#lead-conversion {
    background-color: @influenceGray;

    button {
        .makeButton(#575757, @white, #575757);
    }
}
#crm-loyalty {
    background-color: @influenceGrass;

    button {
        .makeButton(white, #008CCA, white);
    }
}
#training-education {
    background-color: @influenceForest;

    button {
        .makeButton(#FDCC2D, white, #FDCC2D);
    }
}
#winback-remine {
    background-color: @influenceSky;

    button {
        .makeButton(#5F7E1F, white, #5F7E1F);
    }
}
#virtual-coaching {
    background-color: @influenceOrange;

    button {
        .makeButton(#686868, white, #686868);
    }
}
#dhs {
    background-color: @influenceBasicGreen;

    button {
        .makeButton(#EC0072, white, #EC0072);
    }
}


.innovation-gallery-page-footer{
	>.container{
		max-width: 100%;
		
		>.row{
			margin: 0px;
		}
	}
}

//ngDialog Overrides
.ngdialog.ngdialog-theme-default .ngdialog-content {
    width: 960px;
}
.ngdialog.ngdialog-theme-default .ngdialog-content{
	border-radius: 0px;
}

// Demo Video Modal
.ngdialog.ngdialog-theme-default .ngdialog-content {
    padding: 2rem;
}
.demo-video-player {
    max-width: 100%;
}






// Tablet
@media only screen and (min-width: @tabletMinWidth) {
    body.gallery-carousel-locked-view .slider-navigation-container,
    .slider-navigation-container {
        display: block;
    }

    .ivx-innovation-gallery {
        .section-heading,
        .section-subheading {
            text-align: left;
        }
        
	    img {
	        float: right;
	        max-width: none;
	    }        
    }

    .innovation-gallery-section-heading {
        font-size: 4.000rem;
    }
    .innovation-gallery-section-description {
        font-size: 1.375rem;
    }

    .innovation-gallery-section figure {
        margin-left: -1rem;
    }
    .innovation-gallery-demo-link {
        margin-top: 2rem;
    }
}