.makeButton(@backgroundColor, @textColor, @borderColor) {
    background: @backgroundColor;
    color: @textColor;
    border: 1px solid @borderColor;
    text-decoration: none;
    padding: 1.492em 3.016em;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 0.984rem;
    display: inline-block;
}